<template>
  <div>
    <!--begin::User-->
    <div>
      <div v-if="PlanMessage? true: false" class="card-header d-block border-0 pt-6 pb-0">
        <div>
          <b-alert show variant="danger">{{ PlanMessage }}</b-alert>
        </div>
      </div>

      <h3 class="card-label">
        {{ $t('pos_session.pos_session') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <h5>{{ $t('pos_session.session') }} #{{ shift_label ? shift_label + ' /' : '' }}
                  {{ opened_date_label ? opened_date_label : '' }} {{ data.session_id ? '/ ' + data.session_id : '' }}
                  {{ $t('pos_session.cashier') }}: {{ user.first_name }} {{ user.last_name }}</h5>
              </div>

            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <h6>{{ $t('pos_session.session_information') }}</h6>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_session.session') }}</label>
                <input :value="data.session_id" class="form-control" readonly type="text">
                <h6 class="mt-5">#{{ shift_label ? shift_label + ' /' : '' }}
                  {{ opened_date_label ? opened_date_label : '' }} {{
                    data.session_id ? '/ ' + data.session_id : ''
                  }}</h6>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('status') }}</label>
                <select id="status" v-model="data.status" :class="validation && validation.status ? 'is-invalid' : ''"
                        class="custom-select"
                        name="status">
                  <option v-for="row in status_list" :key="row.id" :value="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.status[0] }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <h6>{{ $t('pos_session.POS_information') }}</h6>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_session.shift') }}<span class="text-danger">*</span></label>
                <multiselect id="shift_id"
                             v-model="shift"
                             :class="validation && validation.shift_id ? 'is-invalid' : ''"
                             :multiple="false"
                             :options="shifts"
                             :placeholder="$t('pos_session.shift')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id">
                </multiselect>
                <span v-if="validation && validation.shift_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.shift_id[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_session.device') }}<span class="text-danger">*</span></label>
                <multiselect id="device_id"
                             v-model="device"
                             :class="validation && validation.device_id ? 'is-invalid' : ''"
                             :multiple="false"
                             :options="devices"
                             :placeholder="$t('pos_session.device')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getDefaultTreasuryForDevice">
                </multiselect>
                <span v-if="validation && validation.device_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.device_id[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_session.treasury') }}<span class="text-danger">*</span></label>
                <multiselect v-model="treasury"
                             :class="validation && validation.treasury_id ? 'is-invalid' : ''"
                             :multiple="false"
                             :options="treasuries"
                             :placeholder="$t('pos_session.treasury')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             disabled
                             label="name"
                             track-by="id">
                </multiselect>
                <span v-if="validation && validation.treasury_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.treasury_id[0] }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="my-auto text-white">{{ $t('pos_session.balances') }}</h6>
          </div>
          <div class="bg-white">
            <table class="table table-row-bordered">
              <thead>
              <tr>
                <th>{{ $t('pos_session.balance') }}</th>
                <th>{{ $t('pos_session.currency') }}</th>
                <th>{{ $t('pos_session.confirmed_amount') }}</th>
                <th></th>
              </tr>

              </thead>
              <tbody>
              <template v-if="confirm_treasury_balance.length > 0">
                <tr v-for="(row, index) in confirm_treasury_balance" :key="index">
                  <td><input v-model="row.balance" :disabled="true" class="form-control" min="0.01" step="0.01"
                             type="number"></td>
                  <td>
                    <select id="" v-model="row.currency_id" :disabled="true" class="custom-select" name="">
                      <option v-for="cc in currencies" :key="cc.id" :value="cc.id">{{ cc.name }}</option>
                    </select>
                  </td>
                  <td><input v-model="row.confirmed_amount" class="form-control" min="0.01" step="0.01" type="number">
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="4">{{ $t('no_balances') }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="my-auto text-white">{{ $t('pos_session.details_currency') }}</h6>
            <button class="btn btn-primary" type="button" @click="addMoreInRepeater()">{{ $t('add_more') }}</button>
          </div>

          <div class="bg-white">
            <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addMoreInRepeater()"
                   @keyup.alt.46="removeFromRepeater(0)">
              <thead>
              <tr>
                <th style="width:25%">{{ $t('pos_session.cash_category') }}</th>
                <th style="width:10%">{{ $t('pos_session.cash_category_no') }}</th>
                <th style="width:15%">{{ $t('pos_session.cash_category_amount') }}</th>
                <th style="width:15%"></th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(row, index) in cash_category" :key="index">
                <td>
                  <select v-model="row.cash_category_id"
                          :class="validation && validation['cash_category.'+index+'.cash_category_id'] ? 'is-invalid' : ''"
                          class="custom-select"
                          name="">
                    <option v-for="(ca, index) in cash_category_list_current_currency" :key="index" :value="ca.id">
                      {{ ca.name }}
                    </option>
                  </select>
                  <span v-if="validation && validation['cash_category.'+index+'.cash_category_id']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation['cash_category.'+index+'.cash_category_id'][0] }}
                  </span>
                </td>
                <td>
                  <input v-model="row.cash_category_no"
                         :class="validation && validation['cash_category.'+index+'.cash_category_no'] ? 'is-invalid' : ''"
                         class="form-control"
                         type="number"/>
                  <span v-if="validation && validation['cash_category.'+index+'.cash_category_no']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation['cash_category.' + index + '.cash_category_no'][0] }}
                  </span>
                </td>
                <td>
                  <input v-model="row.amount" :class="validation && validation['cash_category.'+index+'.amount'] ? 'is-invalid' : ''"
                         class="form-control"
                         type="number"/>
                  <span v-if="validation && validation['cash_category.'+index+'.amount']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation['cash_category.' + index + '.amount'][0] }}
                  </span>
                </td>


                <td class="text-center">
                  <v-icon small style="color: #dc3545;" @click="removeFromRepeater(index)">mdi-delete</v-icon>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <div v-if="is_foreign_currency" class="row mt-5">
        <div class="col-md-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="my-auto text-white">{{ $t('pos_session.details_other_currencies') }}</h6>
            <button class="btn btn-primary" type="button" @click="addMoreInRepeaterOther">{{ $t('add_more') }}</button>
          </div>

          <div class="bg-white">
            <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addMoreInRepeaterOther"
                   @keyup.alt.46="removeFromRepeaterOther(0)">
              <thead>
              <tr>
                <th style="width:25%">{{ $t('pos_session.cash_category') }}</th>
                <th style="width:10%">{{ $t('pos_session.cash_category_no') }}</th>
                <th style="width:15%">{{ $t('pos_session.cash_category_amount') }}</th>
                <th style="width:15%"></th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(row, index) in cash_category_other" :key="index">
                <td>
                  <select v-model="row.cash_category_id"
                          :class="validation && validation[index+'.cash_category'] ? 'is-invalid' : ''"
                          class="custom-select"
                          name="">
                    <option v-for="(ca, index) in cash_category_list" :key="index" :value="ca.id">
                      {{ ca.name }}
                    </option>
                  </select>
                  <span v-if="validation && validation[index+'.cash_category_id']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation[index + '.cash_category_id'][0] }}
                  </span>
                </td>
                <td>
                  <input v-model="row.cash_category_no"
                         :class="validation && validation[index+'.cash_category_no'] ? 'is-invalid' : ''"
                         class="form-control"
                         type="number"/>
                  <span v-if="validation && validation[index+'.cash_category_no']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation[index + '.cash_category_no'][0] }}
                  </span>
                </td>
                <td>
                  <input v-model="row.amount" :class="validation && validation[index+'.amount'] ? 'is-invalid' : ''"
                         class="form-control"
                         type="number"/>
                  <span v-if="validation && validation[index+'.amount']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation[index + '.amount'][0] }}
                  </span>
                </td>


                <td class="text-center">
                  <v-icon small style="color: #dc3545;" @click="removeFromRepeaterOther(index)">mdi-delete</v-icon>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button class="btn btn-primary mr-2" type="button" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-pos-session",
  data() {
    return {
      mainRoute: 'pos/pos-sessions',
      mainRouteDependency: 'base/dependency',
      mainRouteTreasury: 'finances/treasuries',

      shifts: [],
      devices: [],
      currencies: [],
      status_list: [],
      user: [],
      customers: [],
      treasuries: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      is_foreign_currency: false,
      data: {
        session_id: null,
        device_id: null,
        shift_id: null,
        session_sales_amount: null,
        // customer_id: null,
        status: 1,
        // opened_date: null,
        // closed_date: null,
        // currency_id: null,
        treasury_id: null,
      },
      isEditing: false,
      validation: null,
      device: null,
      shift: null,
      opened_date_label: null,
      shift_label: null,
      treasury: null,
      PlanMessage: null,
      confirm_treasury_balance: [],
      cash_category_list_current_currency: [],
      cash_category: [],
      cash_category_form: {cash_category_id: null, cash_category_no: null, amount: null, is_other_currency: 0},
      cash_category_list: [],
      cash_category_other: [],
      cash_category_form_other: {cash_category_id: null, cash_category_no: null, amount: null, is_other_currency: 1},

    };
  },
  watch: {
    is_foreign_currency: function (val) {
      if (val) {
        this.getCashCategories();
      }
    },
    device: function (val) {
      if (val) {
        this.data.device_id = val.id;
        this.getCashCategoriesForCurrentCurrency(val.id);
      } else {
        this.data.device_id = null;
        this.cash_category_list_current_currency = [];
      }
    },
    shift: function (val) {
      if (val) {
        this.data.shift_id = val.id;
      } else {
        this.data.shift_id = null;
      }
    },
    // 'data.opened_date': function (val) {
    //     if (val){
    //         this.opened_date_label = this.$moment(val).format('YYYY/MM/DD')
    //     }
    // },
    'data.shift_id': function (val) {
      if (val) {
        let _shift_label = this.shifts.filter(row => row.id == val);
        if (_shift_label[0]) {
          _shift_label = _shift_label[0];
          this.shift_label = _shift_label.name;
        } else {
          this.shift_label = '';
        }
      } else {
        this.shift_label = '';
      }
    },
    treasury: function (val) {
      if (val) {
        this.data.treasury_id = val.id;
        this.getBalancesTreasury(val.id);
      } else {
        this.data.treasury_id = null;
        this.confirm_treasury_balance = [];
      }
    },
  },
  methods: {
    save() {
      // if (this.isEditing) {
      //     this.update();
      // } else {
      //     this.create();
      // }
      this.create();
    },

    create() {
      let $cash_category = this.cash_category;
      $cash_category = $cash_category.filter((row) => {
        return row.amount !== 0 && row.cash_category_no !== 0;
      });
      let $cash_category_other = this.cash_category_other;
      $cash_category_other = $cash_category_other.filter((row) => {
        return row.amount !== 0 && row.cash_category_no !== 0;
      });
      let _cashing = [...$cash_category, ...$cash_category_other];
      ApiService.post(this.mainRoute, {
        confirm_treasury_balance: this.confirm_treasury_balance,
        cash_category: _cashing,
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/pos/pos-session');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      let _cashing = [...this.cash_category, ...this.cash_category_other];
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        confirm_treasury_balance: this.confirm_treasury_balance,
        cash_category: _cashing,
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/pos/pos-session');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    // getData() {
    //     ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
    //         this.isEditing = true;
    //         this.data.session_id = response.data.data.session_id;
    //         this.data.device_id = response.data.data.device_id;
    //         this.data.shift_id = response.data.data.shift_id;
    //         this.data.session_sales_amount = response.data.data.session_sales_amount;
    //         this.data.customer_id = response.data.data.customer_id;
    //         this.data.status = response.data.data.status;
    //         this.data.opened_date = response.data.data.opened_date;
    //         this.data.closed_date = response.data.data.closed_date;
    //         this.data.currency_id = response.data.data.currency_id;
    //         this.data.treasury_id = response.data.data.treasury_id;
    //         this.treasury = response.data.data.treasury;
    //     });
    // },

    getShiftsAndDevices() {
      ApiService.get(this.mainRouteDependency + "/pos_shifts_and_devices").then((response) => {
        this.shifts = response.data.data.pos_shift;
        this.devices = response.data.data.pos_device;
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/session_status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    getAuthUser() {
      ApiService.get(`/get_auth`).then((response) => {
        this.user = response.data.data;
      });
    },
    getCustomers() {
      ApiService.get(this.mainRouteDependency + "/customers").then((response) => {
        this.customers = response.data.data;
      });
    },
    getTreasuries() {
      ApiService.get(this.mainRouteDependency + "/treasuries").then((response) => {
        this.treasuries = response.data.data;
      });
    },
    getPlanMessage() {
      ApiService.get(this.mainRouteDependency + `/check_limits/employees`).then((response) => {
        this.PlanMessage = response.data.data;
      });
    },
    getCashCategories() {
      ApiService.get(this.mainRouteDependency + `/cash_categories`).then((response) => {
        this.cash_category_list = response.data.data;
      });
    },
    getCashCategoriesForCurrentCurrency(id) {
      if (id)
        ApiService.get(this.mainRouteDependency + `/cash_categories_by_device/` + id).then((response) => {
          this.cash_category_list_current_currency = response.data.data;
        }).then(() => {
          this.cash_category = []
          for (let i = 0; i < this.cash_category_list_current_currency.length; i++) {
            this.addMoreInRepeater(i);
          }
        })
    },
    getDefaultTreasuryForDevice(event) {
      if (event && event.id)
        ApiService.get(this.mainRouteDependency + `/pos_default_treasury_for_device/` + event.id).then((response) => {
          this.treasury = response.data.data;
        });
    },
    getBalancesTreasury(id) {
      if (id)
        ApiService.get(this.mainRouteTreasury + `/` + id).then((response) => {
          this.confirm_treasury_balance = response.data.data.items_list.map((row) => {
            return {
              balance: row.balance,
              currency_id: row.currency_id,
              confirmed_amount: row.balance,
            }
          });
        });
    },

    addMoreInRepeater(i = null) {
      if (i != null) {
        this.cash_category_form = {
          cash_category_id: this.cash_category_list_current_currency[i].id,
          cash_category_no: 0,
          amount: 0,
          is_other_currency: 0,
        };
        this.cash_category.push(this.cash_category_form);
      } else {
        this.cash_category_form = {
          cash_category_id: null,
          cash_category_no: null,
          amount: null,
          is_other_currency: 0,
        };
        this.cash_category.unshift(this.cash_category_form);
      }
    },
    removeFromRepeater(index) {
      this.cash_category.splice(index, 1);
    },

    addMoreInRepeaterOther() {
      this.cash_category_other.unshift(this.cash_category_form_other);
      this.cash_category_form_other = {
        cash_category_id: null,
        cash_category_no: null,
        amount: null,
        is_other_currency: 1
      };
    },
    removeFromRepeaterOther(index) {
      this.cash_category_other.splice(index, 1);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.pos_sessions"),
      route: '/pos/pos-session'
    }, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getPlanMessage();
    this.getAuthUser();
    this.getShiftsAndDevices();
    this.getCurrencies();
    this.getStatusList();
    this.getCustomers();
    this.getTreasuries();
    // this.addMoreInRepeater();

    // if (this.idEdit) {
    //     this.getData();
    // }else{}

    this.opened_date_label = this.$moment().format('YYYY/MM/DD');
  },
  created() {
    ApiService.get('pos/pos-settings').then((response) => {
      this.is_foreign_currency = response.data.data.is_foreign_currency;
    });
  }
};
</script>


